//import necessary files for PricingComparison component
import React, { useState } from "react";
import "./PricingComparison.scss";
//import react icon hi library for green checkmark
import { HiArrowDown, HiArrowUp, HiCheck, HiX } from "react-icons/hi";
//import calculateVoipPlanPrice function from utils folder
//import { calculateVoipPlanPrice } from "../utils";
//import cookies hook from react cookies library
import { useCookies } from "react-cookie";
//import calculate voip plan function from utils folder
import { calculateVoIPPlanPrice } from "../../utils";

//create VOIP pricing plan component
export default function MyVoIPPricingPlan({
  name,
  features,
  SetupFee,
  DefaultMinutes,
  DefaultUsers,
  IsPBX = true,
  disc = 0,
  IsEnterprise = false,
}) {
  //extract value of cookie name affid from react cookies library
  const [cookies] = useCookies(["affid", "urlParams"]);
  //extract the value from the dropdown box and set it to a state variable using react useState library
  const [selectedValue, setSelectedValue] = useState(DefaultMinutes);
  const discsavings =
    calculateVoIPPlanPrice(DefaultMinutes, DefaultUsers, IsPBX) * disc;
  const price =
    calculateVoIPPlanPrice(DefaultMinutes, DefaultUsers, IsPBX) -
    discsavings.toFixed(2);
  //console.log(price + ":" + discsavings.toFixed(2));
  const [calculationResult, setCalculationResult] = useState(price.toFixed(2));

  console.log(disc);

  //create a list of features using the map function
  const listFeatures = features.map((feature, index) => (
    <li
      className="d-flex justify-content-left align-items-baseline"
      key={`link-${index}`}
    >
      {feature.available ? (
        <span className="plan-features-available">
          <HiCheck />
        </span>
      ) : (
        <span className="plan-features-unavailable">
          <HiX />
        </span>
      )}
      {feature.name}
    </li>
  ));

  //event handler
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    //using react use state library get the value selected from a dropdown and calculate the total price based on the dopdown box selected and multplies it by 0.59 and returns the result
    const NewPrice =
      calculateVoIPPlanPrice(event.target.value, DefaultUsers, IsPBX) -
      calculateVoIPPlanPrice(event.target.value, DefaultUsers, IsPBX) * disc;
    //console.log(NewPrice);
    setCalculationResult(
      //calculateVoIPPlanPrice(event.target.value, DefaultUsers)
      NewPrice.toFixed(2)
    );
  };

  const $utm_source = cookies.urlParams.utm_source || "";
  const $utm_medium = cookies.urlParams.utm_medium || "";
  const $utm_campaign = cookies.urlParams.utm_campaign || "";
  const $utm_term = cookies.urlParams.utm_term || "";
  const $utm_content = cookies.urlParams.utm_content || "";
  const affid = cookies.affid || "";

  // let affilliateID = "";
  // if (cookies.affid != null) {
  //   affilliateID = cookies.affid;
  //   //log in a console the value of the cookie
  //   //console.log("Cookie set to:" + affilliateID);
  // } else {
  //   //log in a console that the cookie is null
  //   //console.log("cookie is null");
  // }
  //Handle when user click Buy Button and redirect the user to a new url
  const handleBuyNow = (event) => {
    event.preventDefault();

    //redirect to url but open a blank page
    // window.open(
    //   "https://forms.zoho.com/virtualoffice12496/form/MarketDirectcozaBizzVoIPOrderFormBegin/formperma/XkYVfTIyberLQrTinOGe4hazC0dGFVfSxdVOhxGLSMs?minutes=" +
    //     selectedValue +
    //     "&users=" +
    //     DefaultUsers +
    //     "&setupfee=" +
    //     SetupFee +
    //     "&utm_source=marketdirect&utm_medium=btnBuyNow&utm_campaign=The+Bizz+Phone&utm_content=" +
    //     encodeURI(name) +
    //     "&utm_term=" +
    //     "&affid=" +
    //     affilliateID,
    //   "_blank"
    // );
    //redirect to url but open a blank page
    const rfqUrl =
      "https://forms.zoho.com/virtualoffice12496/form/MarketDirectcozaBizzVoIPOrderFormBegin/formperma/XkYVfTIyberLQrTinOGe4hazC0dGFVfSxdVOhxGLSMs?";

    window.open(
      rfqUrl +
        "minutes=" +
        selectedValue +
        "&users=" +
        DefaultUsers +
        "&setupfee=" +
        SetupFee +
        "&product=" +
        encodeURI(name) +
        "&utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleChatNow = (event) => {
    event.preventDefault();

    //redirect to url but open a blank page
    window.open(
      "https://thebizzphone.3cx.co.za/callus/#LiveChat514096",
      "_blank"
    );
  };

  //create a toggle on click handler to show or hide features
  const [isHidden, setIsHidden] = useState(false);
  //prevent the page from scrolling up when the toggle is clicked
  const [scrollY, setScrollY] = useState(0);

  const ToggleVisibility = (event) => {
    setIsHidden(!isHidden);
    event.preventDefault();
    // Prevent the page from scrolling up.
    setScrollY(0);
  };

  //console.log(calculatePrice);
  return (
    //Render a pricing feature list component
    <div className="col">
      <div className="card mb-4 rounded-3 shadow-sm ">
        <div className="card-header py-3">
          <h4 className="my-0 fw-normal text-uppercase">{name}</h4>
        </div>
        <div className="card-body">
          <h1 className="card-title pricing-card-title">
            R{calculationResult}
            <small className="text-body-secondary fw-light">/mo</small>
          </h1>
          <ul className="list-unstyled mt-3 mb-4 feature-list">
            <li className="d-flex justify-content-left align-items-baseline">
              <span className="plan-features-available">
                <HiCheck />
              </span>
              {DefaultUsers}x employee phone extension{DefaultUsers > 1 && "s"}{" "}
              or line{DefaultUsers > 1 && "s"} included
            </li>
            <li>
              <div className="text-bolder text-center">
                <strong>
                  <HiArrowDown />
                  Choose monthly minute bundle(59c/min):
                </strong>
              </div>
              <select
                id="input-starter-minutes"
                className="form-select form-select-sm"
                name="input-starter-minutes"
                onChange={handleChange}
              >
                <option value="0">0 minutes</option>
                <option value="100">100 minutes=R59</option>
                <option value="250">250 minutes=R147.50</option>
                <option value="400">400 minutes=R236</option>
                <option value="550">550 minutes=R325.50</option>
                <option value="700">700 minutes=R413</option>
                <option value="850">850 minutes=R501.50</option>
                <option value="1000">1000 minutes=R590</option>
                <option value="1500">1500 minutes=R885</option>
                <option value="2000">2000 minutes=R1180</option>
              </select>
            </li>
            <li>
              <div className="d-flex justify-content-center align-items-baseline">
                <div className="shadow-sm rounded w-80">
                  Once-off setup fee: <strong>R{SetupFee}</strong>
                </div>
              </div>
              <button
                type="button"
                className="w-100 btn btn-lg btn-outline-primary"
                onClick={handleChatNow}
              >
                Talk to an Expert
              </button>
              <button
                type="button"
                className="w-100 btn btn-lg btn-outline-primary"
                onClick={handleBuyNow}
              >
                {IsEnterprise ? "Contact Us" : "Order Now"}
              </button>
            </li>
            <li className="d-flex justify-content-center align-items-baseline">
              <div className="d-flex justify-content-center">
                <a href="#" onClick={ToggleVisibility}>
                  {isHidden ? (
                    <>
                      <span>Show all features</span>
                      <HiArrowDown />
                    </>
                  ) : (
                    <>
                      <span>Hide all features</span>
                      <HiArrowUp />
                    </>
                  )}
                </a>
              </div>
            </li>
            {/*listFeatures*/}
            {isHidden ? null : listFeatures}
          </ul>
        </div>
      </div>
    </div>
  );
}
