import React from "react";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <section id="privacy">
      <div
        className="container text-center d-flex flex-column justify-content-center pt-6 mx-auto"
        id="Contact"
      >
        <h1 className="display-2">Privacy Policy</h1>
        <div>
          <p>**Effective Date:** May 24, 2024</p>
          <p>
            This Privacy Policy describes how NUMEDIA DIRECT MARKETING(PTY)LTD
            ("we," "us," or "our") collects, uses, and discloses your
            information when you use our website (the "Site").
          </p>
          <h2>Information We Collect:</h2>
          <p>
            We may collect various types of information when you use the Site,
            including:
          </p>
          <ul>
            <li>
              Personal Information: This may include information that can be
              used to identify you, such as your name, email address, phone
              number, and mailing address.
            </li>
            <li>
              Non-Personal Information: This may include information that does
              not identify you personally, such as your browsing activity,
              device information, and demographic information.{" "}
            </li>
          </ul>
          <h2>How We Use Your Information:</h2>
          <p>We may use your information for the following purposes:</p>
          <ul>
            <li>To provide and operate the Site</li>
            <li>To process your requests and inquiries</li>
            <li>To personalize your experience on the Site</li>
            <li>To improve the Site and our services</li>
            <li>
              To send you marketing and promotional communications (with your
              consent)
            </li>
            <li>To comply with legal and regulatory requirements</li>
          </ul>
          <h2>Sharing Your Information:</h2>
          <p>
            We may share your information with third-party service providers who
            help us operate the Site and provide our services. We will only
            share your information with these providers who have agreed to
            comply with our privacy standards.
          </p>
          <p>
            We will not share your information with any third-party for
            marketing purposes without your consent.
          </p>
          <h2>Your Choices: </h2>
          <p>You have choices regarding your information:</p>
          <ul>
            <li>
              You can opt-out of receiving marketing communications from us by
              following the unsubscribe instructions in those communications.
            </li>
            <li>
              You can access and update your personal information through your
              account settings (if applicable).
            </li>
          </ul>
          <h2>Data Security:</h2>
          <p>
            We take reasonable steps to protect your information from
            unauthorized access, disclosure, alteration, or destruction.
            However, no website or internet transmission is completely secure.
          </p>
          <h2>Changes to this Privacy Policy: </h2>
          <p>
            We may update this Privacy Policy from time to time. We will post
            any changes on the Site.
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at:
          </p>
          <p>
            Our contact information is posted below: NUMEDIA DIRECT
            MARKETING(PTY)LTD Email: support@marketdirect.co.za Address: 10
            Falcon Lane, Sandton, Gauteng, 2191 Telephone: +27(087)6312599
            Company ID: 2016/379363/07 VAT No.: 4310304052
          </p>
          <p>
            **This Privacy Policy is incorporated into and subject to the Terms
            and Conditions of Use of the Site.**
          </p>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
