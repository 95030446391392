import React from "react";
//import the css file for styling
import "./BakkieRent.scss";

//import assets from constants
import assets from "../../constants";

//import cookies hook from react cookies library
import { useCookies } from "react-cookie";

// Create a style object and set the `backgroundImage` property to the URL of the background image variable.
const styles = {
  backgroundImage: `url(${assets.bgpofinance1})`,
  opacity: 1,
  backgroundPosition: `top left`,
  bacgroundSize: "cover",
};

//Dispay

//create TenderFundingle
const BakkieRent = (props) => {
  const [cookies] = useCookies(["affid", "urlParams"]);
  //let affilliateID = cookies.affid;
  // if (cookies.affid != null) {
  //   affilliateID = cookies.affid;
  //   //log in a console the value of the cookie
  //   //console.log("Cookie set to:" + affilliateID);
  // } else {
  //   //log in a console that the cookie is null
  //   //console.log("cookie is null");
  // }

  const $utm_source = cookies.urlParams.utm_source || "";
  const $utm_medium = cookies.urlParams.utm_medium || "";
  const $utm_campaign = cookies.urlParams.utm_campaign || "";
  const $utm_term = cookies.urlParams.utm_term || "";
  const $utm_content = cookies.urlParams.utm_content || "";
  const affid = cookies.affid || "";

  //Handle when user click Buy Button and redirect the user to a new url
  const handleRFQButton = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page

    const rfqUrl =
      "https://forms.zoho.com/virtualoffice12496/form/BAKKIEHIREANDDRIVERQUOTEREQUEST/formperma/8qWQe5SCGwGE7mpEPyaBUOV4Aa4nzyrNyjZkzJLrz14?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleLearnMore = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl =
      "https://community.marketdirect.co.za/portal/en-gb/kb/articles/bakkie-hire-frequently-asked-questions?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Chat Button and redirect the user to a new url
  const handleChat = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl = "https://api.whatsapp.com/message/EF2LXKA73QPGJ1";
    window.open(rfqUrl, "_blank");
  };

  const handleBakkieRegistration = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl =
      "https://forms.zoho.com/virtualoffice12496/form/ProductOrderform/formperma/32RXgknoBzgxgeY3gAdOoe1p5LadZn5iMAbyct5GghM";
    window.open(rfqUrl, "_blank");
  };

  return (
    //Tender funding section and content
    <section id="BakkieRent">
      <div>
        <div className="row pt-5 align-items-start">
          <div className="col-sm p-3 order-5 align-self-start">
            <h1 className="display-2 text-white text-left fw-bolder">
              {`Bakkie/Mini Truck Rental for Furniture Removal ${props.city}`}
            </h1>
            <p className="text-white text-left lead">
              <span className="fw-bold">
                Need to yeet your couch to your new Jozi digs or score braai
                supplies for the Joburg jol? Ditch the drama, bru!
              </span>{" "}
              MarketDirect.co.za's bakkie hire gets your goods from A to B
              faster than a Springbok on red bulls.{" "}
              <strong>No deposit drama:</strong> Skip the R2k-R10k cash grab and
              ditch the credit card hassle. We trust you, boet.{" "}
              <strong>Hidden fees? Yoh!</strong> Forget accident cover, contract
              fees, fuel stress, and all that kak. From R450, we get your stuff
              there, straight up. <strong>Moving mountains?</strong> We got your
              back: Couches, beds, office kit, even that dodgy uncle's exercise
              bike – we'll load it all, no sweat.
            </p>
            <div className="d-flex flex-column">
              <div className="p-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleRFQButton}
                >
                  REQUEST A QUOTE
                </a>
              </div>
              <div className="p-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleLearnMore}
                >
                  PRICING
                </a>
              </div>
              <div className="pb-10 text-center">
                <a className="w-50 btn btn-lg btn-primary" onClick={handleChat}>
                  CHAT WITH AN EXPERT
                </a>
              </div>
              <div className="pb-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleBakkieRegistration}
                >
                  REGISTER A BAKKIE
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm p-3 order-1 align-self-start">
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={assets.FurnitureRemovalCase1}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Bakkie Rental and Furniture Removal"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.FurnitureRemovalCase2}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Bakkie Rental and Furniture Removal"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.FurnitureRemoval}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Bakkie Rental and Furniture Removal"
                  />
                </div>
                {/*<div className="carousel-item">
                  <img
                    src={assets.FurnitureRemovalCase4}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Bakkie Rental and Furniture Removal"
                  />
                </div>
                 <div class="carousel-item">
                  <img
                    src={assets.tenderfunderclubgirlreading}
                    class="d-block w-100"
                    className="rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src={assets.tenderfunderclubboyinlibrary}
                    class="d-block w-100"
                    className="rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div> */}
              </div>
            </div>
            {/* <img
              src={assets.tenderfunderclubcutegirl}
              alt="Tender Funder Club"
              className="rounded img-fluid"
            /> */}
          </div>

          {/* <div class="w-100 d-none d-xl-block"></div> */}
          {/* <div className="col0 p-10 mx-auto text-center">
            <a
              className="w-50 btn btn-lg btn-primary"
              onClick={handleApplyButton}
            >
              Apply Now!
            </a>
          </div>
          <div className="text-center">OR</div> */}
        </div>
      </div>
    </section>
  );
};

export default BakkieRent;
