//import sanity client
import { createClient } from "@sanity/client";
//import image url builder
import imageUrlBuilder from "@sanity/image-url";
//create sanity client`
export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_DATASET,
  apiVersion: "2023-10-08",
  useCdn: true,
  token: process.env.REACT_APP_SANITY_API_READ_TOKEN,
});
//create image builder
const builder = imageUrlBuilder(client);
//create url for image
export const urlFor = (source) => builder.image(source);
// Path: frontend\src\client.js
