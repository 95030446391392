import React from "react";
//import the css file for styling
import "./Footer.scss";
//import
import { Link } from "react-router-dom";

//create footer page
const Footer = () => {
  return (
    <footer className="my-5 pt-5 text-body-secondary text-center">
      <div className="container">
        <p className="mb-1 text-white">
          © 2023 MarketDirect.co.za operated by NuMedia Direct
          Marketing(Pty)Ltd. All rights reserved.
        </p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <Link to="/" className="text-white">
              Home
            </Link>
          </li>
          <li className="list-inline-item">
            <Link
              to="/purchase-order-and-tender-finance"
              className="text-white"
            >
              Tender Finance
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/invest" className="text-white">
              Invest: 50-100% Return Per Year
            </Link>
          </li>
          {/* <li className="list-inline-item">
            <Link to="/invoice-discounting" className="text-white">
              Invoice Cash-Advance&trade;
            </Link>
          </li> */}
          <li className="list-inline-item">
            <Link to="/privacy" className="text-white">
              Privacy
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="/terms" className="text-white">
              Terms
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
