import React, { useState, useEffect } from "react";
//import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { Card, Button, CardImgOverlay } from "react-bootstrap";

//import assets from constants
import assets from "../../constants";
//import { AppWrap, MotionWrap } from '../../wrapper';

import "./TenderDeals.scss";

//import cookies hook from react cookies library
import { useCookies } from "react-cookie";
//Import sanity client
import { urlFor, client } from "../../client";

const TenderDeals = () => {
  const [cookies] = useCookies(["affid", "urlParams"]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [TenderDeals, setTenderDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const $utm_source = cookies.urlParams.utm_source || "";
  const $utm_medium = cookies.urlParams.utm_medium || "";
  const $utm_campaign = cookies.urlParams.utm_campaign || "";
  const $utm_term = cookies.urlParams.utm_term || "";
  const $utm_content = cookies.urlParams.utm_content || "";
  const affid = cookies.affid || "";

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    // Fetch data from Deluge API
    const fetchData = async () => {
      try {
        // const response = await fetch("/crm", {
        //   mode: "cors",
        // });
        // const data = await response.json();
        // // Access the output attribute of the details object
        // const output = data.details.output;
        const query = '*[_type == "deals" && publish == true]';
        client?.fetch(query)?.then((data) => {
          setTenderDeals(data);
          console.log("DATA:");
        });
      } catch (error) {
        console.log(`ERROR: ${error}`);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // Empty dependency array to fetch data only once

  // Assuming TenderDeals is a variable holding the parsed JSON data
  if (TenderDeals && TenderDeals?.details?.hasOwnProperty("column_order")) {
    const columns = TenderDeals?.details?.column_order ?? [];
    console.log(`COLUMNS: ${columns}`);
    console.log("DATA: ");
    //console.log(`COLUMNS ORDER: ${columns.toString()}`);
    const rows = TenderDeals?.rows ?? [];
    console.log(`ROWS: ${rows}`);
  } else {
    console.error(
      "Error: 'column_order' property not found in TenderDeals data."
    );
  }

  // An object that maps the index numbers to the index names
  const indexNames = {
    0: "id",
    1: "createdTime",
    2: "npi",
    3: "contractCode",
    4: "projectCode",
    5: "contractorType",
    6: "projectType",
    7: "dealName",
    8: "stage",
    9: "publish",
    10: "poOrInvoiceAmount",
    11: "opportunityAmount",
    12: "profitForShare",
    13: "financeFeePercentage",
    14: "financeFee",
    15: "funderInterest",
    16: "funderFinancePercentage",
    17: "funderProfitShareRatio",
    18: "debtorPaymentTerm",
    19: "deliveryDate",
    20: "supplierLeadTime",
  };

  const handleInterested = (projectcode) => {
    //event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl =
      "https://forms.zoho.com/virtualoffice12496/form/TenderFunderClub/formperma/h3udGGCiIx_Y0MKDj1cUtuSiSpUwDs93qG430eJYjKA?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid +
        "&ref=" +
        projectcode,
      "_blank"
    );
  };

  // Handle when user click Chat Button and redirect the user to a new url
  const handleChat = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl = "https://thebizzphone.3cx.co.za/LiveChat514096";
    window.open(rfqUrl, "_blank");
  };

  const cards = TenderDeals?.rows?.map((deal, index) => {
    return (
      <div key={index} className="col-sm-6 col-md-4 col-lg-4 mb-4">
        <Card>
          {/* Main image */}
          <Card.Img variant="top" src={assets.handtools} />
          {/* Overlay image */}
          <Card.Img
            variant="top"
            src={assets.investmentclosed}
            alt="Investment is closed."
            className="position-absolute top-0 start-0"
          />

          <Card.Body>
            <Card.Title>Hand Tools</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Main Contractor - Simple Delivery
            </Card.Subtitle>
            <Card.Text>
              Project Code: MD75167 <br />
              <strong>
                Invest: ZAR 85 000.00 <br />
                Your Return/Profit Share: ZAR 17 000
                <br />
                Payment Term(days): 15 <br />
              </strong>
              Your Return/Profit Share: 16.19% <br />
              Loan Wanted By: 10/01/2024 <br />
              Loan Payback By: 16/01/2024 <br />
              {/*                   Contract Value: ZAR 189 998.00 <br /> */}
            </Card.Text>
            <Button
              variant="primary"
              id="MD75167"
              onClick={(e) => {
                e.preventDefault();
                handleInterested("MD75167");
              }}
            >
              I WANT TO INVEST NOW
            </Button>{" "}
            <Button variant="primary" onClick={handleChat}>
              CALL/CHAT FREE NOW
            </Button>
          </Card.Body>
        </Card>
      </div>
    );
  });

  //console.log(`ROWS: ${TenderDeals}`);

  return (
    <section id="deals">
      <div className="container text-center d-flex flex-column justify-content-center">
        <h2 className="display-2 mb-4">Choose A Project/Tender to Fund</h2>
        <div className="lead">
          {/* <span className="fw-bold">
            Are you an entrepreneur and get one big project per year followed by
            months of quiet?
          </span> */}
          <span className="fw-bold">
            Need a contract, project or tender? No need to bid if you have
            money, participate in pre-awarded contracts tenders now!
          </span>{" "}
          Don't let your extra money just sit in your bank account till it gets
          finished by bank charges and yourself!{" "}
          <span className="fw-bold">
            In true BROAD-based economic empowerment initiative
          </span>
          , invest your idle money in pre-awarded simple delivery and
          construction tenders and generate ongoing income!
          {/* and generate ongoing revenue while you wait for your next major win. */}
        </div>
        {/* {loading && <p>Loading Tender Deals for investing...</p>} */}
        {/* {error && <p>Error fetching data: {error.message}</p>} */}
        {loading ? (
          <p>Loading deals for investing...</p>
        ) : (
          TenderDeals?.rows?.map((row, index) => (
            <Card key={index}>
              <Card.Body>
                <Card.Title>{row[7]}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {row[5]} - {row[6]}
                </Card.Subtitle>
                <Card.Text>
                  NPI: {row[2]} <br />
                  Contract Code: {row[3]} <br />
                  Project Code: {row[4]} <br />
                  Stage: {row[8]} <br />
                  Publish: {row[9]} <br />
                  PO or Invoice Amount: {row[10]} <br />
                  Opportunity Amount: {row[11]} <br />
                  Profit For Share: {row[12]} <br />
                  Finance Fee Percentage: {row[13]} <br />
                  Finance Fee: {row[14]} <br />
                  Funder Interest: {row[15]} <br />
                  Funder Finance Percentage: {row[16]} <br />
                  Funder Profit Share Ratio: {row[17]} <br />
                  Debtor Payment Term(days): {row[18]} <br />
                  Delivery Date: {row[19]} <br />
                  Supplier Lead Time: {row[20]} <br />
                </Card.Text>
                <Button
                  variant="primary"
                  id={`btn${index}`}
                  onClick={handleInterested(row[4])}
                >
                  CALL ME BACK, AM INTERESTED
                </Button>
              </Card.Body>
            </Card>
          ))
        )}
        <div className="row pt-0 align-items-start">
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.MD76208LandRestoration} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Land Restoration</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Construction
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD76208
                  <br />
                  <strong>
                    Invest: ZAR 30 000 to ZAR 900 000
                    <br />
                    Your Return/Profit Share: ZAR 16, 500 to ZAR 495, 000
                    <br />
                    Payment Term(days): 60 days
                    <br />
                  </strong>
                  Your Return/Profit Share: 55% <br />
                  Loan Wanted By: ASAP <br />
                  {/* Loan Payback By: Monthly for 18 months <br /> */}
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  variant="primary"
                  id="MD76208"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD76208");
                  }}
                  disabled="true"
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img
                variant="top"
                src={assets.InvestSchoolRenovationsMaterialSupply}
              />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>School Renovation Material Supply</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Construction
                </Card.Subtitle>
                <Card.Text>
                  Project Code: SCHOOLRENOVATIONSMP01
                  <br />
                  <strong>
                    Invest: ZAR 100, 000 to ZAR 350 000
                    <br />
                    Your Return/Profit Share: ZAR25, 000 to ZAR 87,500
                    <br />
                    Payment Term(days): 60 days
                    <br />
                  </strong>
                  Your Return/Profit Share: 25% <br />
                  Loan Wanted By: ASAP <br />
                  {/* Loan Payback By: Monthly for 18 months <br /> */}
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  variant="primary"
                  id="SCHOOLRENOVATIONSMP01"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("SCHOOLRENOVATIONSMP01");
                  }}
                  disabled="true"
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investGroceryDelivery} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Grocery Delivery</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75809
                  <br />
                  <strong>
                    Invest: ZAR 1m to ZAR 3.6 million <br />
                    Your Return/Profit Share: ZAR 600K to ZAR 2.1 million
                    <br />
                    Payment Term(days): 18 months
                    <br />
                  </strong>
                  Your Return/Profit Share: 60% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By: Monthly for 18 months <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  variant="primary"
                  id="MD75809"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75809");
                  }}
                  disabled="true"
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img
                variant="top"
                src={assets.InvestInvestRDPMaterialDeliveryOrder}
              />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Government RDP Houses Material Supply</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Construction
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75511
                  <br />
                  <strong>
                    Invest: ZAR 110 000
                    <br />
                    Your Return/Profit Share: ZAR 22 000
                    <br />
                    Payment Term(days): 30
                    <br />
                  </strong>
                  Your Return/Profit Share: 20% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By: 2024/04/20 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  variant="primary"
                  id="MD75511"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75511");
                  }}
                  disabled="true"
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img
                variant="top"
                src={assets.InvestBankFundedHouseConstructionMaterial}
              />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Bank-Funded House Building Material</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Construction
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75511-BOND-HOUSE
                  <br />
                  <strong>
                    Invest: ZAR 110 000
                    <br />
                    Your Return/Profit Share: ZAR 22 000
                    <br />
                    Payment Term(days): 30
                    <br />
                  </strong>
                  Your Return/Profit Share: 20% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By: 2024/04/15 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  variant="primary"
                  id="MD75511-BOND-HOUSE"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75511-BOND-HOUSE");
                  }}
                  disabled="true"
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img
                variant="top"
                src={assets.InvestSteelFramesManufacture}
              />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>4x Steel Frames Manufacture</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Manufacturing
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75844
                  <br />
                  <strong>
                    Invest: ZAR 33 601.91 <br />
                    Your Return/Profit Share: ZAR 6 720.38
                    <br />
                    Payment Term(days): 30
                    <br />
                  </strong>
                  Your Return/Profit Share: 20% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By: 2024/04/10 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MD75844"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75844");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investRDPHouseR120K} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Government Houses Material Supply</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Construction
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75511
                  <br />
                  <strong>
                    Invest: ZAR 110 000 <br />
                    Your Return/Profit Share: ZAR 22 000
                    <br />
                    Payment Term(days): 30
                    <br />
                  </strong>
                  Your Return/Profit Share: 20% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By 2025/03/31 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MD75511"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75511");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button variant="primary" disabled="true" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investSCENARIOC} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Pinter Toners, Heaters And More</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MDGIFTEDSCENE-C
                  <br />
                  <strong>
                    Invest: ZAR 361 050 <br />
                    Your Return/Profit Share: ZAR 54 157
                    <br />
                    Payment Term(days):39 <br />
                  </strong>
                  Your Return/Profit Share: 17.5% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By 2024/03/15 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MDGIFTEDSCENE-C"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MDGIFTEDSCENE-C");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investSCENARIOB} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Nitrile Gloves, Toners, Oil Heaters etc</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MDGIFTEDSCENE-B
                  <br />
                  <strong>
                    Invest: ZAR 659 000.00 <br />
                    Your Return/Profit Share: ZAR 101 000
                    <br />
                    Payment Term(days):39 <br />
                  </strong>
                  Your Return/Profit Share: 31.45% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By 2024/03/15 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MDGIFTEDSCENE-B"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MDGIFTEDSCENE-B");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>

          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img
                variant="top"
                src={assets.investpaintsoilheatersandmore}
              />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Paints, Oil Heaters, And Imaging Drum</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MDGIFTEDSCENE-A
                  <br />
                  <strong>
                    Invest: ZAR 155 088.00 <br />
                    Your Return/Profit Share: ZAR 23 263.20
                    <br />
                    Payment Term(days):39 <br />
                  </strong>
                  Your Return/Profit Share: 16.59% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By 2024/03/15 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MDGIFTEDSCENE-A"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MDGIFTEDSCENE-A");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investcansofpaint} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Paints Supply and Delivery</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75699
                  <br />
                  <strong>
                    Invest: ZAR 89 088.00 <br />
                    Your Return/Profit Share: ZAR 13 363.20
                    <br />
                    Payment Term(days):30 <br />
                  </strong>
                  Your Return/Profit Share: 20.35% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By 2024/03/12 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MD75699"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75699");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investsolar} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Solar System</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MDSOLAR20240119 <br />
                  <strong>
                    Invest: ZAR 2 million <br />
                    Your Return/Profit Share: ZAR 1 million
                    <br />
                    Payment Term(days): 60 <br />
                  </strong>
                  Your Return/Profit Share: 50% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By 30/03/2024 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MD75527"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MDSOLAR20240119");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              {/* Main image */}
              <Card.Img variant="top" src={assets.handtools} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Hand Tools</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75167 <br />
                  <strong>
                    Invest: ZAR 85 000.00 <br />
                    Your Return/Profit Share: ZAR 17 000
                    <br />
                    Payment Term(days): 15 <br />
                  </strong>
                  Your Return/Profit Share: 16.19% <br />
                  Loan Wanted By: 10/01/2024 <br />
                  Loan Payback By: 16/01/2024 <br />
                  {/*                   Contract Value: ZAR 189 998.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MD75167"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75167");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.invest2xrdphouses} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>2X RDP Houses</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Construction
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75511 <br />
                  <strong>
                    Invest: ZAR 504 188.00 <br />
                    Your Return/Profit Share: ZAR 107 392.04
                    <br />
                    Payment Term(days): 60
                    <br />
                  </strong>
                  Your Return/Profit Share: 28.28% <br />
                  Loan Wanted By: 2024-01-19 <br />
                  Loan Payback By: 25/03/2024 <br />
                  {/*                   Contract Value: ZAR 824 840.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MD75511"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75511");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investwashingmachines} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Washing machines</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75527 <br />
                  <strong>
                    Invest: ZAR 163, 998.00 <br />
                    Your Return/Profit Share: ZAR 30 000
                    <br />
                    Payment Term(days): 40 <br />
                  </strong>
                  Your Return/Profit Share: 16.19% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By 28/02/2024 <br />
                  {/*                   Contract Value: ZAR 264 500.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MD75527"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75527");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investsteelgate} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Manufacture Steel Gates</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75546 <br />
                  <strong>
                    Invest: ZAR 42 900.00 <br />
                    Your Return/Profit Share: ZAR 8 580.00
                    <br />
                    Payment Term(days): 30 <br />
                  </strong>
                  Your Return/Profit Share: 16.81% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By: 22/01/2024 <br />
                  {/*                   Contract Value: ZAR 189 998.00 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MD75546"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75546");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investsteeldoors} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Steel Double Doors</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Simple Delivery
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75529 <br />
                  <strong>
                    Invest: ZAR 57 000.00 <br />
                    Your Return/Profit Share: ZAR 11 400.00
                    <br />
                    Payment Term(days): 30 <br />
                  </strong>
                  Your Return/Profit Share: 16.81% <br />
                  Loan Wanted By: ASAP <br />
                  Loan Payback By: 22/02/2024 <br />
                  {/*                   Contract Value: ZAR 189 998.00 <br /> */}
                </Card.Text>
                <Button
                  variant="primary"
                  id="MD75529"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75529");
                  }}
                  disabled="true"
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-4">
            <Card>
              <Card.Img variant="top" src={assets.investschooldemolition} />
              {/* Overlay image */}
              <Card.Img
                variant="top"
                src={assets.investmentclosed}
                alt="Investment is closed."
                className="position-absolute top-0 start-0"
              />
              <Card.Body>
                <Card.Title>Mobile classrooms and demolition of old</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  Main Contractor - Construction
                </Card.Subtitle>
                <Card.Text>
                  Project Code: MD75513 <br />
                  <strong>
                    Invest: ZAR 1 100 000,00 <br />
                    Your Return/Profit Share: ZAR 330 000,00
                    <br />
                    Payment Term(days): 60 <br />
                  </strong>
                  Your Return/Profit Share: 30% <br />
                  Loan Wanted By: 2024-02-01 <br />
                  Loan Payback By: 2024-03-01 <br />
                  {/*                   Contract Value: ZAR 11 069 994,67 <br /> */}
                </Card.Text>
                <Button
                  disabled="true"
                  variant="primary"
                  id="MD75513"
                  onClick={(e) => {
                    e.preventDefault();
                    handleInterested("MD75513");
                  }}
                >
                  I WANT TO INVEST NOW
                </Button>{" "}
                <Button disabled="true" variant="primary" onClick={handleChat}>
                  CALL/CHAT FREE NOW
                </Button>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TenderDeals;

// export default AppWrap(
//   MotionWrap(TenderDeals, 'app__tenderdeals'),
//   'TenderDeals',
//   'app__primarybg',
// );
