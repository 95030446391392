//create a function that calculates the price based on the minutes selected from the dropdown box and number of users
export const calculateVoIPPlanPrice = (
  minutes = 100,
  noUsers = 1,
  pbx = true
) => {
  const baseFee = pbx ? 30 : 30;

  const voipPrice = baseFee + minutes * 0.59 + noUsers * 65;
  // const discAmount = discount > 0 ? voipPrice - voipPrice * discount : 0;
  // const DiscountedTotal = voipPrice - discAmount;
  // console.log(DiscountedTotal + ":" + discount);
  const newPrice = (voipPrice + (voipPrice * 0.035 + 2)) * 1.15;
  //console.log(newPrice);
  return newPrice.toFixed(2);
};
