import React from "react";
//import the css file for styling
import "./About.scss";
//import app wrapper
import { AppWrap, MotionWrap } from "../Wrapper/";

//create about us page
const About = () => {
  //return about us page for our VoIP nextwork
  return (
    <section id="about">
      <div className="container" id="About">
        <h2 className="display-2 text-center mb-4">About us</h2>
        <div className="text-bolder display-6 text-center">
          Level up your business phone communications!
        </div>
        <p className="lead text-left p-2 bg-white shadow rounded text-black d-flex flex-column align-items-center">
          <div className="pr-3">
            We are a telecommunications company with over 20 years of experience
            in tailoring ITC solutions to meet the specific needs of our
            clients. We are not loyal to any one brand, and we always choose the
            best, most cutting-edge ITC solutions for our clients.
          </div>
          <div>
            The Bizz Phone™ is a powerful, budget-friendly cloud-hosted business
            telephone service that uses the latest telecommunications
            technology. Our pricing is based on the customer's exact consumption
            of services, or "on-demand," which allows us to offer businesses
            highly competitive pricing compared to our competitors, who
            typically charge three to four times what we do.
          </div>
        </p>
      </div>
    </section>
  );
};

export default About;

// export default AppWrap(
//   MotionWrap(About, "app__about"),
//   "about",
//   "app__whitebg"
// );
