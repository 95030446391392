import React, { useState } from "react";
//import assets from constants
import assets from "../../constants";
//import the css file for styling
import "./Header.scss";
//import motion from framer
import { motion } from "framer-motion";

//create Header us page
const Header = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  //const [audioUrl, setAudioUrl] = useState(soundUrl);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
  return (
    <header>
      <div id="home" className="app__header pt-6 mx-auto text-center">
        <motion.div
          whileInView={{ x: [-100, 0], opacity: [0, 1] }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className="app__header-info"
        >
          {/* <div className="d-flex justify-content-center d-none">
            <img
              className="w-50"
              src={assets.mobileapp}
              alt="3CX PBX mobile app"
            />
            <img
              className="w-50"
              src={assets.YealinkSIPT33G}
              alt=" Yealink SIP-T33G"
            />
            
          </div> */}
          <h1 className="display-1 fw-bolder">The Bizz Phone&trade;</h1>
          <h4 className="display-4 d-none ">
            Cloud-hosted Loadshedding-Proof VoIP Phone System
          </h4>
          <p className="d-flex flex-column align-items-center lead fw-bold">
            🛑Stop customer loss during loadshedding, we guarantee to deliver
            your missed calls and voicemail by email!🛑
          </p>
          <div className="d-flex flex-column align-items-center">
            <div className="lead pb-2 d-none">
              VoIP stands for Voice over Internet Protocol. It's a way to make
              phone calls using the internet instead of 'old school' phone
              landline copper wires. This means you can make and receive calls
              to and from anywhere in the world for cheaper. You can also use
              VoIP to make video calls, send text messages like SMS, and even
              fax documents. To use VoIP, you need a VoIP app or special phone
              or computer that is connected to the internet.
            </div>
            <p className="pb-2 bold">
              ☎️ Get a trusted business 031,010 virtual landline number that
              works on your cell phone📱 and computer through our{" "}
              <strong>VoIP phone app</strong>. With a virtual landline business
              phone number, you can have two phone lines on one cell phone, so
              you can separate your business and personal calls without having
              to buy a new cell phone or SIM card.
            </p>
            <p className="">
              ☎️ Plus, get Ayanda - your 24/7 digital receptionist who greets
              your customers and records their messages, sends them to you by
              email, even when you are not available after business office
              hours. Stop customer loss due to missed calls, bad voicemail
              services and load-shedding.
            </p>
            {/* <p className="pb-2">
              📕 Do you want our brochure? Download it here 👉🏽
              <a
                href="http://zurl.co/RlM8"
                target="_blank"
                rel="noreferrer"
                className="btn btn-sm btn-primary"
              >
                Download Brochure
              </a>
            </p> */}
          </div>
        </motion.div>
      </div>
      <div>
        <div className="d-flex flex-column align-items-center pb-3">
          <h3 className="text-center">
            Press play to listen to Ayanda greet customers:
          </h3>
          <audio
            src={assets.Ayanda}
            autoPlay={false}
            controls
            onPlay={handlePlay}
            onPause={handlePause}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
