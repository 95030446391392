import React from "react";
//import the css file for styling
import "./ReferAndEarn.scss";
//import assets from constants
import assets from "../../constants";

//create about us page
const About = () => {
  //return about us page for our VoIP nextwork

  return (
    <div className="container" id="ReferAndEarn">
      <section id="refer">
        <h2 className="display-2 text-center mb-4">Refer And Earn</h2>
        <p className="lead text-center">
          <div className="text-bolder display-6">
            Get paid for your referral!
          </div>
          <div className="d-flex flex-row align-items-left p-2 bg-white shadow rounded text-black">
            <img
              src={assets.ReferAndEarn}
              className="rounded  w-50"
              alt="Refer And Earn"
            />
            <div className="text-left">
              Earn a good living by referring businesses as an affiliate or
              agent. The more businesses you refer, the more money you can make.
              <div>
                <a
                  className="btn btn-sm btn-outline-primary"
                  href="https://forms.zoho.com/virtualoffice12496/form/EarnAGoodLivingProgrammePOFunding/formperma/o4UTLCjt6UzZccj0YQgSjvNJ76Gld2gtiqrJE7Q8NHI?utm_source=marketdirect.co.za&utm_medium=btnReferAndEarn&utm_campaign=The%20Bizz%20Phone&utm_content=Refer%20and%20Earn"
                >
                  Join Now
                </a>
                <a
                  className="btn btn-sm btn-outline-primary"
                  href="https://community.marketdirect.co.za/portal/en-gb/kb/articles/earn-a-good-living-programme-commission-tier?utm_source=marketdirect.co.za&utm_medium=btnReferAndEarn&utm_campaign=The%20Bizz%20Phone&utm_content=Refer%20and%20Earn"
                >
                  View Commission Structure Now!
                </a>
              </div>
            </div>
          </div>
        </p>
      </section>
    </div>
  );
};

export default About;
