import React from "react";
//create the react index file
import { createRoot } from "react-dom/client";
import "./index.scss";
//import react cookie for cookie management
import { CookiesProvider } from "react-cookie";
//import bootstrap
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
//import
import App from "./App";
//import Google Tag Manager
//import { GTM } from "react-gtm-module";
//Assign appropriate GTM ID
//const gtm = new GTM("GTM-WK5DW4M");
const root = createRoot(document.getElementById("root")); // createRoot(container!) if you use TypeScript
root.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>
);
