import React from "react";
//import the css file for styling
import "./BizzVoIP.scss";
//import assets from constants
//import assets from "../../constants";
//Import prcing feature list component
import PricingFeatureList from "../../components/PricingComparison/PricingFeatureList";

//import react icon hi library for green checkmark
import { HiCheck, HiX } from "react-icons/hi";
//import react audio player
//import Audio from "react-audio";

//create BizzVoIP us page
const BizzVoIP = () => {
  //create a feature list object with the following features
  const MyLandlineNumberPlan = {
    name: "STARTUP",
    DefaultMinutes: 0,
    DefaultUsers: 1,
    SetupFee: 399,
    IsPBX: false,
    features: [
      {
        name: "1x Virtual Landline 031,021 Number",
        available: true,
      },
      {
        name: "Ayanda - 24/7 digital receptionist",
        available: false,
      },
      {
        name: "Voicemail 2 Email",
        available: true,
      },
      {
        name: "IOS & Android Mobile Apps",
        available: true,
      },
      {
        name: "PC & Web Apps",
        available: true,
      },
      {
        name: "Physical VoIP Phone Connectivity",
        available: false,
      },
      {
        name: "Telephone Conferencing",
        available: false,
      },
      {
        name: "Video Conferencing",
        available: false,
      },
      {
        name: "Website, WhatsApp & Facebook Chat",
        available: false,
      },
      {
        name: "PBX",
        available: false,
      },
      {
        name: "Cloud-hosted",
        available: false,
      },
      {
        name: "Support: Email",
        available: true,
      },
      {
        name: "Support: Phone",
        available: false,
      },
      {
        name: "Help center access",
        available: true,
      },
      {
        name: "Ring Groups",
        available: true,
      },
      {
        name: "Staff SMS integration(ADD-ON)",
        available: false,
      },
      {
        name: "Fax integration(ADD-ON)",
        available: false,
      },
      {
        name: "Queues & Call Center(ADD-ON)",
        available: false,
      },
      {
        name: "Call Recording(ADD-ON)",
        available: false,
      },
      {
        name: "CRM Integration(ADD-ON)",
        available: false,
      },
    ],
  };

  const MyVoIPStarterPlan = {
    name: "ONE-MAN BIZ",
    DefaultMinutes: 100,
    DefaultUsers: 1,
    SetupFee: 399,
    features: [
      {
        name: "1x Virtual Landline 031,021 Number",
        available: true,
      },
      {
        name: "Ayanda - 24/7 digital receptionist",
        available: true,
      },
      {
        name: "Voicemail 2 Email",
        available: true,
      },
      {
        name: "IOS & Android Mobile Apps",
        available: true,
      },
      {
        name: "PC & Web Apps",
        available: true,
      },
      {
        name: "Physical VoIP Phone Connectivity",
        available: true,
      },
      {
        name: "Telephone Conferencing",
        available: true,
      },
      {
        name: "Video Conferencing",
        available: true,
      },
      {
        name: "Website, WhatsApp & Facebook Chat",
        available: true,
      },
      {
        name: "PBX",
        available: true,
      },
      {
        name: "Cloud-hosted",
        available: true,
      },
      {
        name: "Support: Email",
        available: true,
      },
      {
        name: "Support: Phone",
        available: true,
      },
      {
        name: "Help center access",
        available: true,
      },
      {
        name: "Ring Groups",
        available: true,
      },
      {
        name: "Staff SMS integration(ADD-ON)",
        available: false,
      },
      {
        name: "Fax integration(ADD-ON)",
        available: false,
      },
      {
        name: "Queues & Call Center(ADD-ON)",
        available: false,
      },
      {
        name: "Call Recording(ADD-ON)",
        available: false,
      },
      {
        name: "CRM Integration(ADD-ON)",
        available: false,
      },
    ],
  };

  const MyVoIPTeamPlan = {
    name: "TEAM 3X BIZ PHONE LINES",
    defaultPrice: 573,
    DefaultMinutes: 0,
    DefaultUsers: 3,
    SetupFee: 499,
    features: [
      {
        name: "1x Virtual Landline 031,021 Number",
        available: true,
      },
      {
        name: "Ayanda - 24/7 digital receptionist",
        available: true,
      },
      {
        name: "Voicemail 2 Email",
        available: true,
      },
      {
        name: "IOS & Android Mobile Apps",
        available: true,
      },
      {
        name: "PC & Web Apps",
        available: true,
      },
      {
        name: "Physical VoIP Phone Connectivity",
        available: true,
      },
      {
        name: "Telephone Conferencing",
        available: true,
      },
      {
        name: "Video Conferencing",
        available: true,
      },
      {
        name: "Website, WhatsApp & Facebook Chat",
        available: true,
      },
      {
        name: "PBX",
        available: true,
      },
      {
        name: "Cloud-hosted",
        available: true,
      },
      {
        name: "Support: Email",
        available: true,
      },
      {
        name: "Support: Phone",
        available: true,
      },
      {
        name: "Help center access",
        available: true,
      },
      {
        name: "Ring Groups",
        available: true,
      },
      {
        name: "Staff SMS integration(ADD-ON)",
        available: false,
      },
      {
        name: "Fax integration(ADD-ON)",
        available: false,
      },
      {
        name: "Queues & Call Center(ADD-ON)",
        available: false,
      },
      {
        name: "Call Recording(ADD-ON)",
        available: false,
      },
      {
        name: "CRM Integration(ADD-ON)",
        available: false,
      },
    ],
  };

  const MyVoIPGrowthPlan = {
    name: "GROW 5X BIZ PHONE LINES",
    DefaultMinutes: 0,
    DefaultUsers: 5,
    SetupFee: 899,
    features: [
      {
        name: "1x Virtual Landline 031,021 Number",
        available: true,
      },
      {
        name: "Ayanda - 24/7 digital receptionist",
        available: true,
      },
      {
        name: "Voicemail 2 Email",
        available: true,
      },
      {
        name: "IOS & Android Mobile Apps",
        available: true,
      },
      {
        name: "PC & Web Apps",
        available: true,
      },
      {
        name: "Physical VoIP Phone Connectivity",
        available: true,
      },
      {
        name: "Telephone Conferencing",
        available: true,
      },
      {
        name: "Video Conferencing",
        available: true,
      },
      {
        name: "Website, WhatsApp & Facebook Chat",
        available: true,
      },
      {
        name: "PBX",
        available: true,
      },
      {
        name: "Cloud-hosted",
        available: true,
      },
      {
        name: "Support: Email",
        available: true,
      },
      {
        name: "Support: Phone",
        available: true,
      },
      {
        name: "Help center access",
        available: true,
      },
      {
        name: "Ring Groups",
        available: true,
      },
      {
        name: "Staff SMS integration(ADD-ON)",
        available: false,
      },
      {
        name: "Fax integration(ADD-ON)",
        available: false,
      },
      {
        name: "Queues & Call Center(ADD-ON)",
        available: false,
      },
      {
        name: "Call Recording(ADD-ON)",
        available: false,
      },
      {
        name: "CRM Integration(ADD-ON)",
        available: false,
      },
    ],
  };

  const MyVoIPBusinessPlan = {
    name: "BUSINESS 10 LINES",
    DefaultMinutes: 100,
    DefaultUsers: 10,
    SetupFee: 1200,
    features: [
      {
        name: "1x Virtual Landline 031,021 Number",
        available: true,
      },
      {
        name: "Ayanda - 24/7 digital receptionist",
        available: true,
      },
      {
        name: "Voicemail 2 Email",
        available: true,
      },
      {
        name: "IOS & Android Mobile Apps",
        available: true,
      },
      {
        name: "PC & Web Apps",
        available: true,
      },
      {
        name: "Physical VoIP Phone Connectivity",
        available: true,
      },
      {
        name: "Telephone Conferencing",
        available: true,
      },
      {
        name: "Video Conferencing",
        available: true,
      },
      {
        name: "Website, WhatsApp & Facebook Chat",
        available: true,
      },
      {
        name: "PBX",
        available: true,
      },
      {
        name: "Cloud-hosted",
        available: true,
      },
      {
        name: "Support: Email",
        available: true,
      },
      {
        name: "Support: Phone",
        available: true,
      },
      {
        name: "Help center access",
        available: true,
      },
      {
        name: "Ring Groups",
        available: true,
      },
      {
        name: "Staff SMS integration(ADD-ON)",
        available: false,
      },
      {
        name: "Fax integration(ADD-ON)",
        available: false,
      },
      {
        name: "Queues & Call Center(ADD-ON)",
        available: false,
      },
      {
        name: "Call Recording(ADD-ON)",
        available: false,
      },
      {
        name: "CRM Integration(ADD-ON)",
        available: false,
      },
    ],
  };

  const MyVoIPEnterprisePlan = {
    name: "ENTERPRISE",
    defaultPrice: 573,
    DefaultMinutes: 100,
    DefaultUsers: 20,
    SetupFee: 799,
    IsEnterprise: true,
    features: [
      {
        name: "1x Virtual Landline 031,021 Number",
        available: true,
      },
      {
        name: "Ayanda - 24/7 digital receptionist",
        available: true,
      },
      {
        name: "Voicemail 2 Email",
        available: true,
      },
      {
        name: "IOS & Android Mobile Apps",
        available: true,
      },
      {
        name: "PC & Web Apps",
        available: true,
      },
      {
        name: "Physical VoIP Phone Connectivity",
        available: true,
      },
      {
        name: "Telephone Conferencing",
        available: true,
      },
      {
        name: "Video Conferencing",
        available: true,
      },
      {
        name: "Website, WhatsApp & Facebook Chat",
        available: true,
      },
      {
        name: "PBX",
        available: true,
      },
      {
        name: "Cloud-hosted",
        available: true,
      },
      {
        name: "Support: Standard Email",
        available: true,
      },
      {
        name: "Support: Phone",
        available: true,
      },
      {
        name: "Help Center Access",
        available: true,
      },
      {
        name: "Ring Groups",
        available: true,
      },
      {
        name: "Staff SMS integration(ADD-ON)",
        available: false,
      },
      {
        name: "Fax integration(ADD-ON)",
        available: false,
      },
      {
        name: "Queues & Call Center Features",
        available: true,
      },
      {
        name: "Call recording",
        available: true,
      },
      {
        name: "CRM Integration",
        available: true,
      },
    ],
  };

  return (
    //Pricing comparison component
    <section id="pricing">
      <h2 className="display-2 mb-4 text-center">Our VoIP Packages</h2>
      {/* <div className="form-check form-switch text-center d-flex flex-row justify-content-center display-6 mb-2">
        <input
          className="form-check-input"
          type="checkbox"
          id="flexSwitchCheckChecked"
        />
        <label className="form-check-label" for="flexSwitchCheckChecked">
          20% discount if you pay yearly.
        </label>
      </div> */}
      <div className="container" id="BizzVoIP">
        <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
          {/* Render a pricing feature list components MyLandlineNumberPlan */}
          <PricingFeatureList {...MyLandlineNumberPlan} />
          {/* Render a pricing feature list components MyLandlineNumberPlan */}
          {/* <PricingFeatureList {...MyVoIPStarterPlan} /> */}
          {/* Render a pricing feature list components */}
          <PricingFeatureList {...MyVoIPTeamPlan} />
          {/* Render a pricing feature list components */}
          <PricingFeatureList {...MyVoIPGrowthPlan} />
          {/* Render a pricing feature list components */}
          {/* <PricingFeatureList {...MyVoIPBusinessPlan} /> */}
          {/* Render a pricing feature list components */}
          {/* <PricingFeatureList {...MyVoIPEnterprisePlan} /> */}
        </div>
        <h2 className="display-2 text-center mb-4" id="comparison">
          Compare our "TEAM 3X BIZ PHONE LINES" package to major service
          providers in South Africa
        </h2>
        <div className="table-responsive rounded">
          <table className="table text-center">
            <thead>
              <tr>
                <th className="w-34"></th>
                <th className="w-22">The Bizz Phone</th>
                <th className="w-22">Voys</th>
                <th className="w-22">Telkom</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  Extensions/Employee Lines
                </th>
                <td>3</td>
                <td>3</td>
                <td>3</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Total Price
                </th>
                <td>R457.68</td>
                <td>R1200</td>
                <td>R1700</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Price Per User
                </th>
                <td>R152.56</td>
                <td>R400</td>
                <td>R599</td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Unlimited minutes
                </th>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" className="text-start">
                  No 24 Months -Month To Month Contract
                </th>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Ayanda - 24/7 digital receptionist
                </th>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Voicemail 2 Email
                </th>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-unavailable"
                  >
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Queues & Call Center Features
                </th>
                <td>
                  <span className="plan-features-available">
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  IOS & Android Mobile Apps
                </th>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span className="plan-features-unavailable">
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  PC & Web Apps
                </th>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Physical VoIP Phone Connectivity
                </th>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Video Conferencing
                </th>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-unavailable"
                  >
                    <HiX />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-unavailable"
                  >
                    <HiX />
                  </span>
                </td>
              </tr>
              <tr>
                <th scope="row" className="text-start">
                  Website, WhatsApp & Facebook Chat
                </th>
                <td>
                  <span
                    className="
                   plan-features-available"
                  >
                    <HiCheck />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-unavailable"
                  >
                    <HiX />
                  </span>
                </td>
                <td>
                  <span
                    className="
                   plan-features-unavailable"
                  >
                    <HiX />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default BizzVoIP;
