import React from "react";
//import the css file for styling
import "./TenderFunderClub.scss";

//import assets from constants
import assets from "../../constants";

//import cookies hook from react cookies library
import { useCookies } from "react-cookie";

// Create a style object and set the `backgroundImage` property to the URL of the background image variable.
const styles = {
  backgroundImage: `url(${assets.bgpofinance1})`,
  opacity: 1,
  backgroundPosition: `top left`,
  bacgroundSize: "cover",
};

//Dispay

//create TenderFundingle
const TenderFunderClub = (props) => {
  const [cookies] = useCookies(["affid", "urlParams"]);
  //let affilliateID = cookies.affid;
  // if (cookies.affid != null) {
  //   affilliateID = cookies.affid;
  //   //log in a console the value of the cookie
  //   //console.log("Cookie set to:" + affilliateID);
  // } else {
  //   //log in a console that the cookie is null
  //   //console.log("cookie is null");
  // }

  const $utm_source = cookies.urlParams.utm_source || "";
  const $utm_medium = cookies.urlParams.utm_medium || "";
  const $utm_campaign = cookies.urlParams.utm_campaign || "";
  const $utm_term = cookies.urlParams.utm_term || "";
  const $utm_content = cookies.urlParams.utm_content || "";
  const $ref = cookies.urlParams.ref || "";
  const affid = cookies.affid || "";

  //Handle when user click Buy Button and redirect the user to a new url
  const handleRFQButton = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page

    const rfqUrl =
      "https://forms.zohopublic.com/virtualoffice12496/form/TenderFunderClub/formperma/h3udGGCiIx_Y0MKDj1cUtuSiSpUwDs93qG430eJYjKA?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleLearnMore = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl =
      "https://community.marketdirect.co.za/portal/en-gb/kb/articles/platinum-class-funder-welcome-tender-funder-club?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Refer and Earn Button and redirect the user to a new url
  const handleReferAndEarn = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl =
      "https://community.marketdirect.co.za/portal/en-gb/kb/articles/earn-a-good-living-programme-commission-tier?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Chat Button and redirect the user to a new url
  const handleChat = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl = "https://thebizzphone.3cx.co.za/callus/#LiveChat514096";
    window.open(rfqUrl, "_blank");
  };

  return (
    //Tender funding section and content
    <section id="TenderFinance">
      <div>
        <div className="row pt-5 align-items-start">
          <div className="col-sm p-3 order-5 align-self-start">
            <h1 className="display-2 text-white text-left fw-bolder">
              {`MarketDirect Tender Funder Club CrowdFunding Platform ${props.city}`}
            </h1>
            <p className="text-white text-left lead">
              <span className="fw-bold">
                Considering diversifying your investment portfolio? But first, a
                short story about OROS.
              </span>{" "}
              Sure, some wealth managers offer{" "}
              <strong>6-10% return per year orange juice like OROS</strong> -
              the kind that leaves you thirsting for more and wondering if
              there's actually any orange in there. But why settle for
              artificial tang when you can have the juicy, vibrant explosion of{" "}
              <strong>up to 100% return per year real orange juice</strong>?
              Trust me, your portfolio will thank you for skipping the powdery
              imitations. Take a moment and discover how we can help you achieve
              your financial goals. To get started, click or tap any of the blue
              buttons below!
            </p>
            <div className="d-flex flex-column">
              <div className="p-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleRFQButton}
                >
                  REQUEST A CALL BACK PRESS HERE
                </a>
              </div>
              <div className="p-10 text-center">OR</div>
              <div className="pb-10 text-center">
                <a className="w-50 btn btn-lg btn-primary" onClick={handleChat}>
                  CALL US FREE PRESS HERE
                </a>
              </div>
              <div className="p-10 text-center">OR</div>
              <div className="p-10 text-center">
                <a
                  className="w-50 btn btn-lg btn-primary"
                  onClick={handleReferAndEarn}
                >
                  KNOW AN INVESTOR? REFER AND EARN
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm p-3 order-1 align-self-start">
            <div
              id="carouselExampleSlidesOnly"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={assets.tenderfunderclubretiredblackcouple}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.tenderfunderclubcutegirl}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.investfreedomoldwhitemanindroptop}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.investfreedomblackwomanreadonbeach}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.investfreedompetiteblackwomanwalkonbeach}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div>
                <div className="carousel-item">
                  <img
                    src={assets.investfreedomblackyouthinyellow}
                    className="d-block w-100 rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div>
                {/* <div class="carousel-item">
                  <img
                    src={assets.tenderfunderclubgirlreading}
                    class="d-block w-100"
                    className="rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src={assets.tenderfunderclubboyinlibrary}
                    class="d-block w-100"
                    className="rounded img-fluid"
                    alt="MarketDirect Tender Funder Club"
                  />
                </div> */}
              </div>
            </div>
            {/* <img
              src={assets.tenderfunderclubcutegirl}
              alt="Tender Funder Club"
              className="rounded img-fluid"
            /> */}
          </div>

          {/* <div class="w-100 d-none d-xl-block"></div> */}
          {/* <div className="col0 p-10 mx-auto text-center">
            <a
              className="w-50 btn btn-lg btn-primary"
              onClick={handleApplyButton}
            >
              Apply Now!
            </a>
          </div>
          <div className="text-center">OR</div> */}
        </div>
      </div>
    </section>
  );
};

export default TenderFunderClub;
