import React from "react";
//import the css file for styling
import "./InvoiceCashAdvance.scss";

//import assets from constants
import assets from "../../constants";

//import cookies hook from react cookies library
import { useCookies } from "react-cookie";

// Create a style object and set the `backgroundImage` property to the URL of the background image variable.
const styles = {
  backgroundImage: `url(${assets.bgpofinance1})`,
  opacity: 1,
  backgroundPosition: `top left`,
  bacgroundSize: "cover",
};
//create Invoice Cash Advance
const InvoiceCashAdvance = (props) => {
  const [cookies] = useCookies(["affid", "urlParams"]);
  //let affilliateID = cookies.affid;
  // if (cookies.affid != null) {
  //   affilliateID = cookies.affid;
  //   //log in a console the value of the cookie
  //   //console.log("Cookie set to:" + affilliateID);
  // } else {
  //   //log in a console that the cookie is null
  //   //console.log("cookie is null");
  // }
  const $utm_source = cookies.urlParams.utm_source || "";
  const $utm_medium = cookies.urlParams.utm_medium || "";
  const $utm_campaign = cookies.urlParams.utm_campaign || "";
  const $utm_term = cookies.urlParams.utm_term || "";
  const $utm_content = cookies.urlParams.utm_content || "";
  const affid = cookies.affid || "";

  //Handle when user click Buy Button and redirect the user to a new url
  const handleRFQButton = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page

    const rfqUrl =
      "https://forms.zoho.com/virtualoffice12496/form/RFQFormPOFinMarketPlace/formperma/xKZrj2_zBlwPWXvzhtCS5NWgDUcOdDhe4Ro7-dN4Lfk?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  //Handle when user click Buy Button and redirect the user to a new url
  const handleApplyButton = (event) => {
    event.preventDefault();

    //console.log("AFFILIATE ID: " + affid);

    //redirect to url but open a blank page
    const rfqUrl =
      "https://forms.zoho.com/virtualoffice12496/form/MarketDirectcozaInvoiceDiscountingMarketPlace/formperma/E7HJt1Z2pkPEowZl1RPpFYxU9xYEkQQomBdDuzjpOWk?";
    window.open(
      rfqUrl +
        "utm_source=" +
        $utm_source +
        "&utm_medium=" +
        $utm_medium +
        "&utm_campaign=" +
        $utm_campaign +
        "&utm_content=" +
        $utm_content +
        "&utm_term=" +
        $utm_term +
        "&affid=" +
        affid,
      "_blank"
    );
  };

  return (
    //Tender funding section and content
    <section id="TenderFinance">
      <div>
        <div className="row pt-5 align-items-center">
          <div className="p-3 p-1">
            <h1 className="col0 display-2 text-white text-center">
              {`Invoice Cash-Advance or Discounting ${props.city}`}
            </h1>
            <p className="lead text-white text-center">
              Are you tired of waiting 30 - 90 days for your unpaid government
              invoice? We offer advance payments of up to 70% of your unpaid
              invoices, with your 30% balance to be paid to you when you client
              pays, minus our fees. You can access funding from R50 000 to R1
              Billion with no credit checks required. Just fill out our
              application form and get a quote from us today or contact us. To
              get started, click or tap the blue button below!
            </p>
          </div>
          {/* <div class="w-100 d-none d-xl-block"></div> */}
          <div className="col0 p-10 mx-auto text-center">
            <a
              className="w-50 btn btn-lg btn-primary"
              onClick={handleApplyButton}
            >
              Apply Now!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InvoiceCashAdvance;
