import React from "react";
//import the css file for styling
import "./Contact.scss";
//Import the map component
import { AddressMap } from "../../components/";

//create Contact us page
const Contact = () => {
  return (
    <section id="contact">
      <div className="contaner">
        <h2 className="display-2 text-center mb-4">Contact us</h2>
        <p className="lead text-center">
          What are you waiting for? Upgrade your bizz game and make your bizz
          trusted by customers, and trust means more sales!
        </p>
        <p className="text-center display-1 fw-bolder text-white">
          ☎️
          <a href="tel:+27876312599" className="text-white">
            0876312599
          </a>
        </p>

        <p className="text-center display-4">
          🏪 10 Falcon Lane, Sandton, Gauteng, 2191
        </p>
        <div className="text-center">
          <a
            title="Chat Now"
            href="https://thebizzphone.3cx.co.za/callus/#LiveChat514096"
            target="_blank"
            type="button"
            class="btn btn-primary btn-lg"
          >
            Talk to an Expert
          </a>
        </div>
        <div>{/* <AddressMap /> */}</div>
      </div>
    </section>
  );
};

export default Contact;
